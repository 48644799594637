import { Box } from 'grommet';

export const DraftsIcon = (props: {
  size?: number;
  color?: string;
  selected?: boolean;
}) => {
  const color = props.color || '#4B5563';
  const size = props.size || 18;

  if (props.selected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none">
        <path
          d="M6.4875 2.40002C5.55552 2.40002 4.8 3.15554 4.8 4.08752V19.6125C4.8 20.5445 5.55552 21.3 6.4875 21.3H17.9625C18.8945 21.3 19.65 20.5445 19.65 19.6125V12.525C19.65 10.6611 18.139 9.15002 16.275 9.15002H14.5875C13.6555 9.15002 12.9 8.3945 12.9 7.46252V5.77502C12.9 3.91106 11.389 2.40002 9.525 2.40002H6.4875Z"
          fill={color}
        />
        <path
          d="M13.0991 2.68434C13.8162 3.51285 14.25 4.59328 14.25 5.77502V7.46252C14.25 7.64892 14.4011 7.80002 14.5875 7.80002H16.275C17.4567 7.80002 18.5372 8.23386 19.3657 8.95095C18.5666 5.89191 16.1581 3.48342 13.0991 2.68434Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="-1.5 1 24 24"
        fill="none">
        <path
          d="M18.3 14.4V12.0375C18.3 10.3599 16.9401 8.99998 15.2625 8.99998H13.9125C13.3533 8.99998 12.9 8.54666 12.9 7.98748V6.63748C12.9 4.95991 11.5401 3.59998 9.8625 3.59998H8.175M10.2 3.59998H5.8125C5.25331 3.59998 4.8 4.05329 4.8 4.61248V20.1375C4.8 20.6967 5.25331 21.15 5.8125 21.15H17.2875C17.8467 21.15 18.3 20.6967 18.3 20.1375V11.7C18.3 7.22647 14.6735 3.59998 10.2 3.59998Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
