export const POSTHOG_EVENTS = {
  CLICKED_GET_STARTED: 'clicked_get_started',
  CLICKED_CONTINUE: 'clicked_continue',
  CLICKED_KEYWORD: 'clicked_keyword',
  CLICKED_REF_LABEL: 'clicked_ref_label',
  CLICKED_AGGREGATED_REF_LABEL: 'clicked_aggregated_ref_label',
  CLICKED_PROFILE_PAGE: 'clicked_profile_page',
  CLICKED_REFERENCE_PAGE: 'clicked_reference_page',
  CLICKED_FEED_TAB: 'clicked_feed_tab',
  CLICKED_YOUR_POSTS_TAB: 'clicked_your_posts_tab',
  CLICKED_HYPERFEED_TAB: 'clicked_hyperfeed_tab',
  CLICKED_SETTINGS_TAB: 'clicked_settings_tab',
  CLICKED_ORIGINAL_POST_URL: 'clicked_original_post_url',
  CLICKED_CONNECT_PLATFORM_ACCOUNT: 'clicked_connect_platform_account',
  CLICKED_POST_VIEW: 'clicked_post_view',
  CLICKED_REF_URL: 'clicked_ref_url',
  CLICKED_PROFILE_URL: 'clicked_profile_url',
  TOGGLE_SHARE_TO_HYPERFEED: 'toggle_share_to_hyperfeed',
};
