import { Box } from 'grommet';

export const WorldIcon = (props: { size?: number }) => {
  const size = props.size || 18;
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size - 1}
        height={size}
        viewBox="0 0 16 17"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 1.92859C4.41015 1.92859 1.5 4.83874 1.5 8.42859C1.5 12.0184 4.41015 14.9286 8 14.9286C11.5899 14.9286 14.5 12.0184 14.5 8.42859C14.5 4.83874 11.5899 1.92859 8 1.92859ZM5.69792 3.43209C3.81011 4.30332 2.5 6.21296 2.5 8.42859C2.5 11.4662 4.96243 13.9286 8 13.9286C10.8761 13.9286 13.2365 11.7211 13.4794 8.90797L13.1721 8.60071C13.0581 8.48671 12.9633 8.35507 12.8912 8.21087L12.1711 6.77079C12.0869 6.60244 11.8626 6.56603 11.7295 6.69912C11.5879 6.84075 11.3806 6.89446 11.188 6.83944L10.3394 6.59698C10.0171 6.5049 9.68264 6.69808 9.60135 7.02325C9.54058 7.26633 9.63918 7.52136 9.84766 7.66035L10.2388 7.92112C10.6326 8.18365 10.6878 8.74082 10.3531 9.07549L10.2197 9.20892C10.079 9.34957 10 9.54034 10 9.73925V10.0131C10 10.285 9.92613 10.5517 9.78626 10.7849L8.90984 12.2455C8.65559 12.6693 8.19764 12.9286 7.70346 12.9286C7.31496 12.9286 7.00002 12.6136 7.00002 12.2252V11.4441C7.00002 10.8308 6.62659 10.2792 6.0571 10.0514L5.62101 9.87699C4.96652 9.61519 4.58262 8.93299 4.6985 8.23767L4.7032 8.20952C4.73413 8.02389 4.79973 7.84574 4.89655 7.68437L4.95616 7.58503C5.2823 7.04144 5.91496 6.76158 6.53657 6.8859L7.3218 7.04294C7.70492 7.11957 8.08269 6.88923 8.19003 6.51355L8.32902 6.02708C8.42817 5.68006 8.2661 5.31163 7.94329 5.15022L7.50002 4.92859L7.43936 4.98925C7.15805 5.27055 6.77652 5.42859 6.3787 5.42859H6.25808C6.09285 5.42859 5.93399 5.49462 5.81715 5.61146C5.62817 5.80044 5.33878 5.84797 5.09974 5.72845C4.77624 5.5667 4.6589 5.16378 4.84499 4.85363L5.69792 3.43209Z"
          fill="#1F2937"
        />
      </svg>
    </Box>
  );
};
