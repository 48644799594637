import { Box } from 'grommet';

export const SettignsIcon = (props: {
  size?: number;
  color?: string;
  selected?: boolean;
}) => {
  const color = props.color || '#4B5563';
  const size = props.size || 18;

  if (props.selected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0779 2.25C10.1613 2.25 9.37909 2.91265 9.22841 3.81675L9.04974 4.88873C9.02959 5.00964 8.93542 5.1498 8.75311 5.23747C8.40905 5.40292 8.07967 5.5938 7.7674 5.8076C7.60091 5.92159 7.43259 5.9332 7.31769 5.89015L6.29851 5.50833C5.44019 5.18678 4.4752 5.53289 4.01692 6.32666L3.09493 7.92358C2.63665 8.71736 2.8194 9.72611 3.52704 10.3087L4.36756 11.0006C4.46219 11.0785 4.53629 11.2298 4.52119 11.4307C4.50706 11.6188 4.49988 11.8086 4.49988 12C4.49988 12.1915 4.50707 12.3814 4.52121 12.5695C4.53632 12.7704 4.46221 12.9217 4.36758 12.9996L3.52704 13.6916C2.8194 14.2741 2.63665 15.2829 3.09493 16.0767L4.01692 17.6736C4.4752 18.4674 5.44019 18.8135 6.29851 18.4919L7.31791 18.11C7.43281 18.067 7.60113 18.0786 7.76761 18.1925C8.07982 18.4063 8.40913 18.5971 8.75311 18.7625C8.93542 18.8502 9.02959 18.9904 9.04974 19.1113L9.22841 20.1832C9.37909 21.0874 10.1613 21.75 11.0779 21.75H12.9219C13.8384 21.75 14.6207 21.0874 14.7713 20.1832L14.95 19.1113C14.9702 18.9904 15.0643 18.8502 15.2466 18.7625C15.5907 18.5971 15.9201 18.4062 16.2324 18.1924C16.3988 18.0784 16.5672 18.0668 16.6821 18.1098L17.7012 18.4917C18.5596 18.8132 19.5246 18.4671 19.9828 17.6733L20.9048 16.0764C21.3631 15.2826 21.1804 14.2739 20.4727 13.6913L19.6322 12.9994C19.5376 12.9215 19.4635 12.7702 19.4786 12.5693C19.4927 12.3812 19.4999 12.1914 19.4999 12C19.4999 11.8085 19.4927 11.6186 19.4785 11.4305C19.4634 11.2296 19.5375 11.0783 19.6322 11.0004L20.4727 10.3084C21.1804 9.72587 21.3631 8.71711 20.9048 7.92334L19.9828 6.32642C19.5246 5.53264 18.5596 5.18654 17.7012 5.50809L16.6818 5.89C16.5669 5.93304 16.3986 5.92144 16.2321 5.80746C15.9199 5.59371 15.5906 5.40289 15.2466 5.23747C15.0643 5.1498 14.9702 5.00964 14.95 4.88873L14.7713 3.81675C14.6207 2.91265 13.8384 2.25 12.9219 2.25H11.0779ZM12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <Box>
      <svg
        width={size + 1}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.84377 3.94005C9.93418 3.39759 10.4035 3 10.9535 3H13.5474C14.0974 3 14.5667 3.39759 14.6571 3.94005L14.8706 5.2211C14.933 5.59514 15.1829 5.90671 15.5147 6.09036C15.5889 6.13142 15.6622 6.17383 15.7346 6.21757C16.0597 6.41384 16.455 6.47486 16.8106 6.34166L18.0274 5.88578C18.5424 5.69284 19.1214 5.90051 19.3964 6.37677L20.6934 8.62321C20.9683 9.09948 20.8587 9.70473 20.4341 10.0543L19.4298 10.8811C19.1373 11.1219 18.9923 11.4937 18.9993 11.8725C19.0001 11.9149 19.0004 11.9574 19.0004 12C19.0004 12.0426 19.0001 12.0851 18.9993 12.1275C18.9923 12.5063 19.1373 12.8781 19.4298 13.1189L20.4341 13.9457C20.8587 14.2953 20.9683 14.9005 20.6934 15.3768L19.3964 17.6232C19.1214 18.0995 18.5424 18.3071 18.0274 18.1142L16.8106 17.6583C16.455 17.5251 16.0597 17.5862 15.7347 17.7824C15.6622 17.8262 15.5889 17.8686 15.5147 17.9096C15.1829 18.0933 14.933 18.4049 14.8706 18.7789L14.6571 20.0599C14.5667 20.6024 14.0974 21 13.5474 21H10.9535C10.4035 21 9.93418 20.6024 9.84377 20.0599L9.63026 18.7789C9.56792 18.4049 9.31795 18.0933 8.98618 17.9096C8.91201 17.8686 8.83869 17.8262 8.76626 17.7824C8.44122 17.5862 8.04589 17.5251 7.69033 17.6583L6.47346 18.1142C5.95847 18.3072 5.37948 18.0995 5.1045 17.6232L3.80752 15.3768C3.53255 14.9005 3.6422 14.2953 4.06678 13.9457L5.07114 13.1189C5.36364 12.8781 5.50864 12.5063 5.50163 12.1275C5.50084 12.0851 5.50045 12.0426 5.50045 12C5.50045 11.9574 5.50084 11.9149 5.50163 11.8725C5.50864 11.4937 5.36364 11.1219 5.07114 10.8811L4.06678 10.0543C3.6422 9.70475 3.53255 9.09949 3.80752 8.62323L5.1045 6.37679C5.37948 5.90052 5.95847 5.69286 6.47346 5.88579L7.69031 6.34167C8.04588 6.47487 8.44121 6.41385 8.76624 6.21758C8.83868 6.17384 8.912 6.13142 8.98618 6.09036C9.31795 5.90671 9.56792 5.59514 9.63026 5.2211L9.84377 3.94005Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2502 11.9999C15.2502 13.6568 13.9071 14.9999 12.2502 14.9999C10.5934 14.9999 9.25022 13.6568 9.25022 11.9999C9.25022 10.3431 10.5934 8.99992 12.2502 8.99992C13.9071 8.99992 15.2502 10.3431 15.2502 11.9999Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
