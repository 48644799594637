import { Box } from 'grommet';

export const ClusterIcon = (props: { size?: number; color?: string }) => {
  const color = props.color || '#6B7280';
  const size = props.size || 18;

  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size - 1}
        height={size}
        viewBox="0 0 16 17"
        fill="none">
        <path
          d="M11.9999 12.908C12.1649 12.9216 12.3318 12.9286 12.5003 12.9286C13.1992 12.9286 13.8702 12.8091 14.4939 12.5894C14.4981 12.5364 14.5003 12.4827 14.5003 12.4286C14.5003 11.324 13.6049 10.4286 12.5003 10.4286C12.0819 10.4286 11.6935 10.5571 11.3724 10.7767M11.9999 12.908C11.9999 12.9149 11.9999 12.9217 11.9999 12.9286C11.9999 13.0786 11.9916 13.2266 11.9756 13.3724C10.8044 14.0444 9.44702 14.4286 7.9999 14.4286C6.55279 14.4286 5.19542 14.0444 4.02424 13.3724C4.00816 13.2266 3.9999 13.0786 3.9999 12.9286C3.9999 12.9218 3.99992 12.9149 3.99995 12.9081M11.9999 12.908C11.9959 12.1236 11.7662 11.3927 11.3724 10.7767M11.3724 10.7767C10.6618 9.66538 9.41688 8.92859 7.9999 8.92859C6.5831 8.92859 5.33834 9.6652 4.6277 10.7763M4.6277 10.7763C4.30672 10.5569 3.91854 10.4286 3.50039 10.4286C2.39582 10.4286 1.50039 11.324 1.50039 12.4286C1.50039 12.4827 1.50254 12.5364 1.50676 12.5894C2.13052 12.8091 2.8015 12.9286 3.50039 12.9286C3.66862 12.9286 3.83523 12.9217 3.99995 12.9081M4.6277 10.7763C4.23371 11.3924 4.00389 12.1235 3.99995 12.9081M9.9999 4.92859C9.9999 6.03316 9.10447 6.92859 7.9999 6.92859C6.89533 6.92859 5.9999 6.03316 5.9999 4.92859C5.9999 3.82402 6.89533 2.92859 7.9999 2.92859C9.10447 2.92859 9.9999 3.82402 9.9999 4.92859ZM13.9999 6.92859C13.9999 7.75702 13.3283 8.42859 12.4999 8.42859C11.6715 8.42859 10.9999 7.75702 10.9999 6.92859C10.9999 6.10016 11.6715 5.42859 12.4999 5.42859C13.3283 5.42859 13.9999 6.10016 13.9999 6.92859ZM4.9999 6.92859C4.9999 7.75702 4.32833 8.42859 3.4999 8.42859C2.67148 8.42859 1.9999 7.75702 1.9999 6.92859C1.9999 6.10016 2.67148 5.42859 3.4999 5.42859C4.32833 5.42859 4.9999 6.10016 4.9999 6.92859Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

export const ClusterIconSelected = (props: {
  size?: number;
  color?: string;
}) => {
  const color = props.color || '#111827';
  const size = props.size || 18;

  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size - 1}
        height={size}
        viewBox="0 0 16 17"
        fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 4.72223C5.5 3.34152 6.61929 2.22223 8 2.22223C9.38071 2.22223 10.5 3.34152 10.5 4.72223C10.5 6.10294 9.38071 7.22223 8 7.22223C6.61929 7.22223 5.5 6.10294 5.5 4.72223Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 6.72223C10.5 5.61766 11.3954 4.72223 12.5 4.72223C13.6046 4.72223 14.5 5.61766 14.5 6.72223C14.5 7.8268 13.6046 8.72223 12.5 8.72223C11.3954 8.72223 10.5 7.8268 10.5 6.72223Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 6.72223C1.5 5.61766 2.39543 4.72223 3.5 4.72223C4.60457 4.72223 5.5 5.61766 5.5 6.72223C5.5 7.8268 4.60457 8.72223 3.5 8.72223C2.39543 8.72223 1.5 7.8268 1.5 6.72223Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.20657 10.3006C5.00522 9.05184 6.40557 8.22223 8 8.22223C9.59463 8.22223 10.9951 9.05204 11.7937 10.301C12.3458 11.1645 12.5847 12.2057 12.4726 13.2208C12.4551 13.3795 12.3629 13.5202 12.2245 13.5997C10.9796 14.314 9.53672 14.7222 8 14.7222C6.46328 14.7222 5.02039 14.314 3.7755 13.5997C3.63706 13.5202 3.54486 13.3795 3.52736 13.2208C3.41529 12.2055 3.65427 11.1641 4.20657 10.3006Z"
          fill={color}
        />
        <path d="M3.38819 9.72469C3.38016 9.73702 3.37218 9.74938 3.36425 9.76179C2.72061 10.7682 2.42605 11.9667 2.51648 13.1482C2.11111 13.0867 1.7178 12.9876 1.34029 12.8547L1.26365 12.8277C1.12384 12.7784 1.02615 12.6515 1.01439 12.5037L1.00795 12.4227C1.00268 12.3565 1 12.2896 1 12.2222C1 10.879 2.05936 9.78317 3.38819 9.72469Z" />
        <path
          d="M13.4838 13.1482C13.5742 11.9669 13.2797 10.7686 12.6363 9.76235C12.6283 9.74976 12.6202 9.73721 12.6121 9.7247C13.9408 9.78331 15 10.8791 15 12.2222C15 12.2896 14.9973 12.3565 14.9921 12.4227L14.9856 12.5037C14.9738 12.6515 14.8762 12.7784 14.7364 12.8277L14.6597 12.8547C14.2823 12.9876 13.889 13.0866 13.4838 13.1482Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};
